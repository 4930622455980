<template>
  <div class="text-center">
    <v-dialog class="dialogBox" v-model="dialog" :persistent="true" max-width="340" @keydown.esc="cancel">
      <div class="addToCartBox" style="display: flex; align-items: center; flex-direction: column">
        <img src="@/assets/zeplin/question-1.svg" alt="" />
        <div class="mb-5">
          <h3 v-show="!!title" v-html="title"></h3>
          <p v-show="!!message" v-html="message"></p>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" depressed dark rounded x-large @click.native="agree">
            <h3>{{ options.confirmBtn ? options.confirmBtn : this.$t(confirmBtn) }}</h3>
          </v-btn>
          <v-btn color="#b92d32" depressed dark rounded x-large @click.native="cancel">
            <h3>{{ options.backBtn ? options.backBtn : this.$t("dialogButton.back") }}</h3>
          </v-btn>
        </v-card-actions>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ConfirmDlg",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
        confirmBtn: null,
        backBtn: null
      }
    };
  },

  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  },
  props: {
    confirmBtn: {
      type: String,
      default: "dialogButton.confirm"
    }
  }
};
</script>
