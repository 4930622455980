/**
 * The list of all countries with their 2 digit codes (ISO 3166-2)
 *
 * @see http://data.okfn.org/data/core/country-list
 */

module.exports = [
  {
    en: "Afghanistan",
    "alpha-3": "AFG",
    zhHans: "阿富汗",
    zhHant: "阿富汗"
  },
  {
    en: "Åland Islands",
    "alpha-3": "ALA",
    zhHans: "奥兰",
    zhHant: "奧蘭"
  },
  {
    en: "Albania",
    "alpha-3": "ALB",
    zhHans: "阿尔巴尼亚",
    zhHant: "阿爾巴尼亞"
  },
  {
    en: "Algeria",
    "alpha-3": "DZA",
    zhHans: "阿尔及利亚",
    zhHant: "阿爾及利亞"
  },
  {
    en: "American Samoa",
    "alpha-3": "ASM",
    zhHans: "美属萨摩亚",
    zhHant: "美屬薩摩亞"
  },
  {
    en: "Andorra",
    "alpha-3": "AND",
    zhHans: "安道尔",
    zhHant: "安道爾"
  },
  {
    en: "Angola",
    "alpha-3": "AGO",
    zhHans: "安哥拉",
    zhHant: "安哥拉"
  },
  {
    en: "Anguilla",
    "alpha-3": "AIA",
    zhHans: "安圭拉",
    zhHant: "安圭拉"
  },
  {
    en: "Antarctica",
    "alpha-3": "ATA",
    zhHans: "南极洲",
    zhHant: "南極洲"
  },
  {
    en: "Antigua and Barbuda",
    "alpha-3": "ATG",
    zhHans: "安提瓜和巴布达",
    zhHant: "安地卡及巴布達"
  },
  {
    en: "Argentina",
    "alpha-3": "ARG",
    zhHans: "阿根廷",
    zhHant: "阿根廷"
  },
  {
    en: "Armenia",
    "alpha-3": "ARM",
    zhHans: "亚美尼亚",
    zhHant: "亞美尼亞"
  },
  {
    en: "Aruba",
    "alpha-3": "ABW",
    zhHans: "阿鲁巴",
    zhHant: "阿魯巴"
  },
  {
    en: "Australia",
    "alpha-3": "AUS",
    zhHans: "澳大利亚",
    zhHant: "澳大利亞"
  },
  {
    en: "Austria",
    "alpha-3": "AUT",
    zhHans: "奥地利",
    zhHant: "奧地利"
  },
  {
    en: "Azerbaijan",
    "alpha-3": "AZE",
    zhHans: "阿塞拜疆",
    zhHant: "亞塞拜然"
  },
  {
    en: "Bahamas",
    "alpha-3": "BHS",
    zhHans: "巴哈马",
    zhHant: "巴哈馬"
  },
  {
    en: "Bahrain",
    "alpha-3": "BHR",
    zhHans: "巴林",
    zhHant: "巴林"
  },
  {
    en: "Bangladesh",
    "alpha-3": "BGD",
    zhHans: "孟加拉国",
    zhHant: "孟加拉"
  },
  {
    en: "Barbados",
    "alpha-3": "BRB",
    zhHans: "巴巴多斯",
    zhHant: "巴貝多"
  },
  {
    en: "Belarus",
    "alpha-3": "BLR",
    zhHans: "白俄罗斯",
    zhHant: "白俄羅斯"
  },
  {
    en: "Belgium",
    "alpha-3": "BEL",
    zhHans: "比利时",
    zhHant: "比利時"
  },
  {
    en: "Belize",
    "alpha-3": "BLZ",
    zhHans: "伯利兹",
    zhHant: "貝里斯"
  },
  {
    en: "Benin",
    "alpha-3": "BEN",
    zhHans: "贝宁",
    zhHant: "貝南"
  },
  {
    en: "Bermuda",
    "alpha-3": "BMU",
    zhHans: "百慕大",
    zhHant: "百慕達"
  },
  {
    en: "Bhutan",
    "alpha-3": "BTN",
    zhHans: "不丹",
    zhHant: "不丹"
  },
  {
    en: "Bolivia (Plurinational State of)",
    "alpha-3": "BOL",
    zhHans: "玻利维亚",
    zhHant: "玻利維亞"
  },
  {
    en: "Bonaire, Sint Eustatius and Saba",
    "alpha-3": "BES",
    zhHans: "荷兰加勒比区",
    zhHant: "荷蘭加勒比區"
  },
  {
    en: "Bosnia and Herzegovina",
    "alpha-3": "BIH",
    zhHans: "波黑",
    zhHant: "波赫"
  },
  {
    en: "Botswana",
    "alpha-3": "BWA",
    zhHans: "博茨瓦纳",
    zhHant: "波札那"
  },
  {
    en: "Bouvet Island",
    "alpha-3": "BVT",
    zhHans: "布韦岛",
    zhHant: "布韋島"
  },
  {
    en: "Brazil",
    "alpha-3": "BRA",
    zhHans: "巴西",
    zhHant: "巴西"
  },
  {
    en: "British Indian Ocean Territory",
    "alpha-3": "IOT",
    zhHans: "英属印度洋领地",
    zhHant: "英屬印度洋領地"
  },
  {
    en: "Brunei Darussalam",
    "alpha-3": "BRN",
    zhHans: "文莱",
    zhHant: "汶萊"
  },
  {
    en: "Bulgaria",
    "alpha-3": "BGR",
    zhHans: "保加利亚",
    zhHant: "保加利亞"
  },
  {
    en: "Burkina Faso",
    "alpha-3": "BFA",
    zhHans: "布基纳法索",
    zhHant: "布吉納法索"
  },
  {
    en: "Burundi",
    "alpha-3": "BDI",
    zhHans: "布隆迪",
    zhHant: "蒲隆地"
  },
  {
    en: "Cabo Verde",
    "alpha-3": "CPV",
    zhHans: "佛得角",
    zhHant: "維德角"
  },
  {
    en: "Cambodia",
    "alpha-3": "KHM",
    zhHans: "柬埔寨",
    zhHant: "柬埔寨"
  },
  {
    en: "Cameroon",
    "alpha-3": "CMR",
    zhHans: "喀麦隆",
    zhHant: "喀麥隆"
  },
  {
    en: "Canada",
    "alpha-3": "CAN",
    zhHans: "加拿大",
    zhHant: "加拿大"
  },
  {
    en: "Cayman Islands",
    "alpha-3": "CYM",
    zhHans: "开曼群岛",
    zhHant: "開曼群島"
  },
  {
    en: "Central African Republic",
    "alpha-3": "CAF",
    zhHans: "中非",
    zhHant: "中非"
  },
  {
    en: "Chad",
    "alpha-3": "TCD",
    zhHans: "乍得",
    zhHant: "查德"
  },
  {
    en: "Chile",
    "alpha-3": "CHL",
    zhHans: "智利",
    zhHant: "智利"
  },
  {
    en: "China",
    "alpha-3": "CHN",
    zhHans: "中国",
    zhHant: "中國"
  },
  {
    en: "Christmas Island",
    "alpha-3": "CXR",
    zhHans: "圣诞岛",
    zhHant: "聖誕島"
  },
  {
    en: "Cocos (Keeling) Islands",
    "alpha-3": "CCK",
    zhHans: "科科斯（基林）群岛",
    zhHant: "科科斯（基林）群島"
  },
  {
    en: "Colombia",
    "alpha-3": "COL",
    zhHans: "哥伦比亚",
    zhHant: "哥倫比亞"
  },
  {
    en: "Comoros",
    "alpha-3": "COM",
    zhHans: "科摩罗",
    zhHant: "葛摩"
  },
  {
    en: "Congo",
    "alpha-3": "COG",
    zhHans: "刚果共和国",
    zhHant: "剛果共和國"
  },
  {
    en: "Congo (Democratic Republic of the)",
    "alpha-3": "COD",
    zhHans: "刚果民主共和国",
    zhHant: "剛果民主共和國"
  },
  {
    en: "Cook Islands",
    "alpha-3": "COK",
    zhHans: "库克群岛",
    zhHant: "庫克群島"
  },
  {
    en: "Costa Rica",
    "alpha-3": "CRI",
    zhHans: "哥斯达黎加",
    zhHant: "哥斯大黎加"
  },
  {
    en: "Côte d'Ivoire",
    "alpha-3": "CIV",
    zhHans: "科特迪瓦",
    zhHant: "象牙海岸"
  },
  {
    en: "Croatia",
    "alpha-3": "HRV",
    zhHans: "克罗地亚",
    zhHant: "克羅埃西亞"
  },
  {
    en: "Cuba",
    "alpha-3": "CUB",
    zhHans: "古巴",
    zhHant: "古巴"
  },
  {
    en: "Curaçao",
    "alpha-3": "CUW",
    zhHans: "库拉索",
    zhHant: "古拉索"
  },
  {
    en: "Cyprus",
    "alpha-3": "CYP",
    zhHans: "塞浦路斯",
    zhHant: "賽普勒斯"
  },
  {
    en: "Czechia",
    "alpha-3": "CZE",
    zhHans: "捷克",
    zhHant: "捷克"
  },
  {
    en: "Denmark",
    "alpha-3": "DNK",
    zhHans: "丹麦",
    zhHant: "丹麥"
  },
  {
    en: "Djibouti",
    "alpha-3": "DJI",
    zhHans: "吉布提",
    zhHant: "吉布地"
  },
  {
    en: "Dominica",
    "alpha-3": "DMA",
    zhHans: "多米尼克",
    zhHant: "多米尼克"
  },
  {
    en: "Dominican Republic",
    "alpha-3": "DOM",
    zhHans: "多米尼加",
    zhHant: "多明尼加"
  },
  {
    en: "Ecuador",
    "alpha-3": "ECU",
    zhHans: "厄瓜多尔",
    zhHant: "厄瓜多"
  },
  {
    en: "Egypt",
    "alpha-3": "EGY",
    zhHans: "埃及",
    zhHant: "埃及"
  },
  {
    en: "El Salvador",
    "alpha-3": "SLV",
    zhHans: "萨尔瓦多",
    zhHant: "薩爾瓦多"
  },
  {
    en: "Equatorial Guinea",
    "alpha-3": "GNQ",
    zhHans: "赤道几内亚",
    zhHant: "赤道幾內亞"
  },
  {
    en: "Eritrea",
    "alpha-3": "ERI",
    zhHans: "厄立特里亚",
    zhHant: "厄利垂亞"
  },
  {
    en: "Estonia",
    "alpha-3": "EST",
    zhHans: "爱沙尼亚",
    zhHant: "愛沙尼亞"
  },
  {
    en: "Eswatini",
    "alpha-3": "SWZ",
    zhHans: "斯威士兰",
    zhHant: "史瓦帝尼"
  },
  {
    en: "Ethiopia",
    "alpha-3": "ETH",
    zhHans: "埃塞俄比亚",
    zhHant: "衣索比亞"
  },
  {
    en: "Falkland Islands (Malvinas)",
    "alpha-3": "FLK",
    zhHans: "福克兰群岛",
    zhHant: "福克蘭群島"
  },
  {
    en: "Faroe Islands",
    "alpha-3": "FRO",
    zhHans: "法罗群岛",
    zhHant: "法羅群島"
  },
  {
    en: "Fiji",
    "alpha-3": "FJI",
    zhHans: "斐济",
    zhHant: "斐濟"
  },
  {
    en: "Finland",
    "alpha-3": "FIN",
    zhHans: "芬兰",
    zhHant: "芬蘭"
  },
  {
    en: "France",
    "alpha-3": "FRA",
    zhHans: "法国",
    zhHant: "法國"
  },
  {
    en: "French Guiana",
    "alpha-3": "GUF",
    zhHans: "法属圭亚那",
    zhHant: "法屬圭亞那"
  },
  {
    en: "French Polynesia",
    "alpha-3": "PYF",
    zhHans: "法属波利尼西亚",
    zhHant: "法屬玻里尼西亞"
  },
  {
    en: "French Southern Territories",
    "alpha-3": "ATF",
    zhHans: "法属南部和南极领地",
    zhHant: "法屬南部和南極領地"
  },
  {
    en: "Gabon",
    "alpha-3": "GAB",
    zhHans: "加蓬",
    zhHant: "加彭"
  },
  {
    en: "Gambia",
    "alpha-3": "GMB",
    zhHans: "冈比亚",
    zhHant: "甘比亞"
  },
  {
    en: "Georgia",
    "alpha-3": "GEO",
    zhHans: "格鲁吉亚",
    zhHant: "喬治亞"
  },
  {
    en: "Germany",
    "alpha-3": "DEU",
    zhHans: "德国",
    zhHant: "德國"
  },
  {
    en: "Ghana",
    "alpha-3": "GHA",
    zhHans: "加纳",
    zhHant: "加納"
  },
  {
    en: "Gibraltar",
    "alpha-3": "GIB",
    zhHans: "直布罗陀",
    zhHant: "直布羅陀"
  },
  {
    en: "Greece",
    "alpha-3": "GRC",
    zhHans: "希腊",
    zhHant: "希臘"
  },
  {
    en: "Greenland",
    "alpha-3": "GRL",
    zhHans: "格陵兰",
    zhHant: "格陵蘭"
  },
  {
    en: "Grenada",
    "alpha-3": "GRD",
    zhHans: "格林纳达",
    zhHant: "格瑞那達"
  },
  {
    en: "Guadeloupe",
    "alpha-3": "GLP",
    zhHans: "瓜德罗普",
    zhHant: "瓜地洛普"
  },
  {
    en: "Guam",
    "alpha-3": "GUM",
    zhHans: "关岛",
    zhHant: "關島"
  },
  {
    en: "Guatemala",
    "alpha-3": "GTM",
    zhHans: "危地马拉",
    zhHant: "瓜地馬拉"
  },
  {
    en: "Guernsey",
    "alpha-3": "GGY",
    zhHans: "根西",
    zhHant: "根西"
  },
  {
    en: "Guinea",
    "alpha-3": "GIN",
    zhHans: "几内亚",
    zhHant: "幾內亞"
  },
  {
    en: "Guinea-Bissau",
    "alpha-3": "GNB",
    zhHans: "几内亚比绍",
    zhHant: "幾內亞比索"
  },
  {
    en: "Guyana",
    "alpha-3": "GUY",
    zhHans: "圭亚那",
    zhHant: "蓋亞那"
  },
  {
    en: "Haiti",
    "alpha-3": "HTI",
    zhHans: "海地",
    zhHant: "海地"
  },
  {
    en: "Heard Island and McDonald Islands",
    "alpha-3": "HMD",
    zhHans: "赫德岛和麦克唐纳群岛",
    zhHant: "赫德島和麥克唐納群島"
  },
  {
    en: "Holy See",
    "alpha-3": "VAT",
    zhHans: "梵蒂冈",
    zhHant: "梵蒂岡"
  },
  {
    en: "Honduras",
    "alpha-3": "HND",
    zhHans: "洪都拉斯",
    zhHant: "宏都拉斯"
  },
  {
    en: "Hong Kong",
    "alpha-3": "HKG",
    zhHans: "香港",
    zhHant: "香港"
  },
  {
    en: "Hungary",
    "alpha-3": "HUN",
    zhHans: "匈牙利",
    zhHant: "匈牙利"
  },
  {
    en: "Iceland",
    "alpha-3": "ISL",
    zhHans: "冰岛",
    zhHant: "冰島"
  },
  {
    en: "India",
    "alpha-3": "IND",
    zhHans: "印度",
    zhHant: "印度"
  },
  {
    en: "Indonesia",
    "alpha-3": "IDN",
    zhHans: "印尼",
    zhHant: "印尼"
  },
  {
    en: "Iran (Islamic Republic of)",
    "alpha-3": "IRN",
    zhHans: "伊朗",
    zhHant: "伊朗"
  },
  {
    en: "Iraq",
    "alpha-3": "IRQ",
    zhHans: "伊拉克",
    zhHant: "伊拉克"
  },
  {
    en: "Ireland",
    "alpha-3": "IRL",
    zhHans: "爱尔兰",
    zhHant: "愛爾蘭"
  },
  {
    en: "Isle of Man",
    "alpha-3": "IMN",
    zhHans: "马恩岛",
    zhHant: "曼島"
  },
  {
    en: "Israel",
    "alpha-3": "ISR",
    zhHans: "以色列",
    zhHant: "以色列"
  },
  {
    en: "Italy",
    "alpha-3": "ITA",
    zhHans: "意大利",
    zhHant: "義大利"
  },
  {
    en: "Jamaica",
    "alpha-3": "JAM",
    zhHans: "牙买加",
    zhHant: "牙買加"
  },
  {
    en: "Japan",
    "alpha-3": "JPN",
    zhHans: "日本",
    zhHant: "日本"
  },
  {
    en: "Jersey",
    "alpha-3": "JEY",
    zhHans: "泽西",
    zhHant: "澤西"
  },
  {
    en: "Jordan",
    "alpha-3": "JOR",
    zhHans: "约旦",
    zhHant: "約旦"
  },
  {
    en: "Kazakhstan",
    "alpha-3": "KAZ",
    zhHans: "哈萨克斯坦",
    zhHant: "哈薩克"
  },
  {
    en: "Kenya",
    "alpha-3": "KEN",
    zhHans: "肯尼亚",
    zhHant: "肯亞"
  },
  {
    en: "Kiribati",
    "alpha-3": "KIR",
    zhHans: "基里巴斯",
    zhHant: "吉里巴斯"
  },
  {
    en: "Korea (Democratic People's Republic of)",
    "alpha-3": "PRK",
    zhHans: "朝鲜",
    zhHant: "北韓"
  },
  {
    en: "Korea (Republic of)",
    "alpha-3": "KOR",
    zhHans: "韩国",
    zhHant: "韓國"
  },
  {
    en: "Kuwait",
    "alpha-3": "KWT",
    zhHans: "科威特",
    zhHant: "科威特"
  },
  {
    en: "Kyrgyzstan",
    "alpha-3": "KGZ",
    zhHans: "吉尔吉斯斯坦",
    zhHant: "吉爾吉斯"
  },
  {
    en: "Lao People's Democratic Republic",
    "alpha-3": "LAO",
    zhHans: "老挝",
    zhHant: "寮國"
  },
  {
    en: "Latvia",
    "alpha-3": "LVA",
    zhHans: "拉脱维亚",
    zhHant: "拉脫維亞"
  },
  {
    en: "Lebanon",
    "alpha-3": "LBN",
    zhHans: "黎巴嫩",
    zhHant: "黎巴嫩"
  },
  {
    en: "Lesotho",
    "alpha-3": "LSO",
    zhHans: "莱索托",
    zhHant: "賴索托"
  },
  {
    en: "Liberia",
    "alpha-3": "LBR",
    zhHans: "利比里亚",
    zhHant: "賴比瑞亞"
  },
  {
    en: "Libya",
    "alpha-3": "LBY",
    zhHans: "利比亚",
    zhHant: "利比亞"
  },
  {
    en: "Liechtenstein",
    "alpha-3": "LIE",
    zhHans: "列支敦士登",
    zhHant: "列支敦斯登"
  },
  {
    en: "Lithuania",
    "alpha-3": "LTU",
    zhHans: "立陶宛",
    zhHant: "立陶宛"
  },
  {
    en: "Luxembourg",
    "alpha-3": "LUX",
    zhHans: "卢森堡",
    zhHant: "盧森堡"
  },
  {
    en: "Macao",
    "alpha-3": "MAC",
    zhHans: "澳门",
    zhHant: "澳門"
  },
  {
    en: "Madagascar",
    "alpha-3": "MDG",
    zhHans: "马达加斯加",
    zhHant: "馬達加斯加"
  },
  {
    en: "Malawi",
    "alpha-3": "MWI",
    zhHans: "马拉维",
    zhHant: "馬拉威"
  },
  {
    en: "Malaysia",
    "alpha-3": "MYS",
    zhHans: "马来西亚",
    zhHant: "馬來西亞"
  },
  {
    en: "Maldives",
    "alpha-3": "MDV",
    zhHans: "马尔代夫",
    zhHant: "馬爾地夫"
  },
  {
    en: "Mali",
    "alpha-3": "MLI",
    zhHans: "马里",
    zhHant: "馬利"
  },
  {
    en: "Malta",
    "alpha-3": "MLT",
    zhHans: "马耳他",
    zhHant: "馬爾他"
  },
  {
    en: "Marshall Islands",
    "alpha-3": "MHL",
    zhHans: "马绍尔群岛",
    zhHant: "馬紹爾群島"
  },
  {
    en: "Martinique",
    "alpha-3": "MTQ",
    zhHans: "马提尼克",
    zhHant: "馬丁尼克"
  },
  {
    en: "Mauritania",
    "alpha-3": "MRT",
    zhHans: "毛里塔尼亚",
    zhHant: "茅利塔尼亞"
  },
  {
    en: "Mauritius",
    "alpha-3": "MUS",
    zhHans: "毛里求斯",
    zhHant: "模里西斯"
  },
  {
    en: "Mayotte",
    "alpha-3": "MYT",
    zhHans: "马约特",
    zhHant: "馬約特"
  },
  {
    en: "Mexico",
    "alpha-3": "MEX",
    zhHans: "墨西哥",
    zhHant: "墨西哥"
  },
  {
    en: "Micronesia (Federated States of)",
    "alpha-3": "FSM",
    zhHans: "密克罗尼西亚联邦",
    zhHant: "密克羅尼西亞聯邦"
  },
  {
    en: "Moldova (Republic of)",
    "alpha-3": "MDA",
    zhHans: "摩尔多瓦",
    zhHant: "摩爾多瓦"
  },
  {
    en: "Monaco",
    "alpha-3": "MCO",
    zhHans: "摩纳哥",
    zhHant: "摩納哥"
  },
  {
    en: "Mongolia",
    "alpha-3": "MNG",
    zhHans: "蒙古",
    zhHant: "蒙古"
  },
  {
    en: "Montenegro",
    "alpha-3": "MNE",
    zhHans: "黑山",
    zhHant: "蒙特內哥羅"
  },
  {
    en: "Montserrat",
    "alpha-3": "MSR",
    zhHans: "蒙特塞拉特",
    zhHant: "蒙特塞拉特"
  },
  {
    en: "Morocco",
    "alpha-3": "MAR",
    zhHans: "摩洛哥",
    zhHant: "摩洛哥"
  },
  {
    en: "Mozambique",
    "alpha-3": "MOZ",
    zhHans: "莫桑比克",
    zhHant: "莫三比克"
  },
  {
    en: "Myanmar",
    "alpha-3": "MMR",
    zhHans: "缅甸",
    zhHant: "緬甸"
  },
  {
    en: "Namibia",
    "alpha-3": "NAM",
    zhHans: "纳米比亚",
    zhHant: "納米比亞"
  },
  {
    en: "Nauru",
    "alpha-3": "NRU",
    zhHans: "瑙鲁",
    zhHant: "諾魯"
  },
  {
    en: "Nepal",
    "alpha-3": "NPL",
    zhHans: "尼泊尔",
    zhHant: "尼泊爾"
  },
  {
    en: "Netherlands",
    "alpha-3": "NLD",
    zhHans: "荷兰",
    zhHant: "荷蘭"
  },
  {
    en: "New Caledonia",
    "alpha-3": "NCL",
    zhHans: "新喀里多尼亚",
    zhHant: "新喀里多尼亞"
  },
  {
    en: "New Zealand",
    "alpha-3": "NZL",
    zhHans: "新西兰",
    zhHant: "紐西蘭"
  },
  {
    en: "Nicaragua",
    "alpha-3": "NIC",
    zhHans: "尼加拉瓜",
    zhHant: "尼加拉瓜"
  },
  {
    en: "Niger",
    "alpha-3": "NER",
    zhHans: "尼日尔",
    zhHant: "尼日"
  },
  {
    en: "Nigeria",
    "alpha-3": "NGA",
    zhHans: "尼日利亚",
    zhHant: "奈及利亞"
  },
  {
    en: "Niue",
    "alpha-3": "NIU",
    zhHans: "纽埃",
    zhHant: "紐埃"
  },
  {
    en: "Norfolk Island",
    "alpha-3": "NFK",
    zhHans: "诺福克岛",
    zhHant: "諾福克島"
  },
  {
    en: "North Macedonia",
    "alpha-3": "MKD",
    zhHans: "北马其顿",
    zhHant: "北馬其頓"
  },
  {
    en: "Northern Mariana Islands",
    "alpha-3": "MNP",
    zhHans: "北马里亚纳群岛",
    zhHant: "北馬利安納群島"
  },
  {
    en: "Norway",
    "alpha-3": "NOR",
    zhHans: "挪威",
    zhHant: "挪威"
  },
  {
    en: "Oman",
    "alpha-3": "OMN",
    zhHans: "阿曼",
    zhHant: "阿曼"
  },
  {
    en: "Pakistan",
    "alpha-3": "PAK",
    zhHans: "巴基斯坦",
    zhHant: "巴基斯坦"
  },
  {
    en: "Palau",
    "alpha-3": "PLW",
    zhHans: "帕劳",
    zhHant: "帛琉"
  },
  {
    en: "Palestine, State of",
    "alpha-3": "PSE",
    zhHans: "巴勒斯坦",
    zhHant: "巴勒斯坦"
  },
  {
    en: "Panama",
    "alpha-3": "PAN",
    zhHans: "巴拿马",
    zhHant: "巴拿馬"
  },
  {
    en: "Papua New Guinea",
    "alpha-3": "PNG",
    zhHans: "巴布亚新几内亚",
    zhHant: "巴布亞紐幾內亞"
  },
  {
    en: "Paraguay",
    "alpha-3": "PRY",
    zhHans: "巴拉圭",
    zhHant: "巴拉圭"
  },
  {
    en: "Peru",
    "alpha-3": "PER",
    zhHans: "秘鲁",
    zhHant: "秘魯"
  },
  {
    en: "Philippines",
    "alpha-3": "PHL",
    zhHans: "菲律宾",
    zhHant: "菲律賓"
  },
  {
    en: "Pitcairn",
    "alpha-3": "PCN",
    zhHans: "皮特凯恩群岛",
    zhHant: "皮特肯群島"
  },
  {
    en: "Poland",
    "alpha-3": "POL",
    zhHans: "波兰",
    zhHant: "波蘭"
  },
  {
    en: "Portugal",
    "alpha-3": "PRT",
    zhHans: "葡萄牙",
    zhHant: "葡萄牙"
  },
  {
    en: "Puerto Rico",
    "alpha-3": "PRI",
    zhHans: "波多黎各",
    zhHant: "波多黎各"
  },
  {
    en: "Qatar",
    "alpha-3": "QAT",
    zhHans: "卡塔尔",
    zhHant: "卡達"
  },
  {
    en: "Réunion",
    "alpha-3": "REU",
    zhHans: "留尼汪",
    zhHant: "留尼旺"
  },
  {
    en: "Romania",
    "alpha-3": "ROU",
    zhHans: "罗马尼亚",
    zhHant: "羅馬尼亞"
  },
  {
    en: "Russian Federation",
    "alpha-3": "RUS",
    zhHans: "俄罗斯",
    zhHant: "俄羅斯"
  },
  {
    en: "Rwanda",
    "alpha-3": "RWA",
    zhHans: "卢旺达",
    zhHant: "盧安達"
  },
  {
    en: "Saint Barthélemy",
    "alpha-3": "BLM",
    zhHans: "圣巴泰勒米",
    zhHant: "聖巴瑟米"
  },
  {
    en: "Saint Helena, Ascension and Tristan da Cunha",
    "alpha-3": "SHN",
    zhHans: "圣赫勒拿、阿森松和特里斯坦-达库尼亚",
    zhHant: "聖赫倫那、阿森松和特里斯坦-達庫尼亞"
  },
  {
    en: "Saint Kitts and Nevis",
    "alpha-3": "KNA",
    zhHans: "圣基茨和尼维斯",
    zhHant: "聖克里斯多福及尼維斯"
  },
  {
    en: "Saint Lucia",
    "alpha-3": "LCA",
    zhHans: "圣卢西亚",
    zhHant: "聖露西亞"
  },
  {
    en: "Saint Martin (French part)",
    "alpha-3": "MAF",
    zhHans: "法属圣马丁",
    zhHant: "法屬聖馬丁"
  },
  {
    en: "Saint Pierre and Miquelon",
    "alpha-3": "SPM",
    zhHans: "圣皮埃尔和密克隆",
    zhHant: "聖皮埃與密克隆群島"
  },
  {
    en: "Saint Vincent and the Grenadines",
    "alpha-3": "VCT",
    zhHans: "圣文森特和格林纳丁斯",
    zhHant: "聖文森及格瑞那丁"
  },
  {
    en: "Samoa",
    "alpha-3": "WSM",
    zhHans: "萨摩亚",
    zhHant: "薩摩亞"
  },
  {
    en: "San Marino",
    "alpha-3": "SMR",
    zhHans: "圣马力诺",
    zhHant: "聖馬利諾"
  },
  {
    en: "Sao Tome and Principe",
    "alpha-3": "STP",
    zhHans: "圣多美和普林西比",
    zhHant: "聖多美普林西比"
  },
  {
    en: "Saudi Arabia",
    "alpha-3": "SAU",
    zhHans: "沙特阿拉伯",
    zhHant: "沙烏地阿拉伯"
  },
  {
    en: "Senegal",
    "alpha-3": "SEN",
    zhHans: "塞内加尔",
    zhHant: "塞內加爾"
  },
  {
    en: "Serbia",
    "alpha-3": "SRB",
    zhHans: "塞尔维亚",
    zhHant: "塞爾維亞"
  },
  {
    en: "Seychelles",
    "alpha-3": "SYC",
    zhHans: "塞舌尔",
    zhHant: "塞席爾"
  },
  {
    en: "Sierra Leone",
    "alpha-3": "SLE",
    zhHans: "塞拉利昂",
    zhHant: "塞拉里昂"
  },
  {
    en: "Singapore",
    "alpha-3": "SGP",
    zhHans: "新加坡",
    zhHant: "新加坡"
  },
  {
    en: "Sint Maarten (Dutch part)",
    "alpha-3": "SXM",
    zhHans: "荷属圣马丁",
    zhHant: "荷屬聖馬丁"
  },
  {
    en: "Slovakia",
    "alpha-3": "SVK",
    zhHans: "斯洛伐克",
    zhHant: "斯洛伐克"
  },
  {
    en: "Slovenia",
    "alpha-3": "SVN",
    zhHans: "斯洛文尼亚",
    zhHant: "斯洛維尼亞"
  },
  {
    en: "Solomon Islands",
    "alpha-3": "SLB",
    zhHans: "所罗门群岛",
    zhHant: "索羅門群島"
  },
  {
    en: "Somalia",
    "alpha-3": "SOM",
    zhHans: "索马里",
    zhHant: "索馬利亞"
  },
  {
    en: "South Africa",
    "alpha-3": "ZAF",
    zhHans: "南非",
    zhHant: "南非"
  },
  {
    en: "South Georgia and the South Sandwich Islands",
    "alpha-3": "SGS",
    zhHans: "南乔治亚和南桑威奇群岛",
    zhHant: "南喬治亞和南桑威奇群島"
  },
  {
    en: "South Sudan",
    "alpha-3": "SSD",
    zhHans: "南苏丹",
    zhHant: "南蘇丹"
  },
  {
    en: "Spain",
    "alpha-3": "ESP",
    zhHans: "西班牙",
    zhHant: "西班牙"
  },
  {
    en: "Sri Lanka",
    "alpha-3": "LKA",
    zhHans: "斯里兰卡",
    zhHant: "斯里蘭卡"
  },
  {
    en: "Sudan",
    "alpha-3": "SDN",
    zhHans: "苏丹",
    zhHant: "蘇丹"
  },
  {
    en: "Suriname",
    "alpha-3": "SUR",
    zhHans: "苏里南",
    zhHant: "蘇利南"
  },
  {
    en: "Svalbard and Jan Mayen",
    "alpha-3": "SJM",
    zhHans: "斯瓦尔巴和扬马延",
    zhHant: "斯瓦爾巴和揚馬延"
  },
  {
    en: "Sweden",
    "alpha-3": "SWE",
    zhHans: "瑞典",
    zhHant: "瑞典"
  },
  {
    en: "Switzerland",
    "alpha-3": "CHE",
    zhHans: "瑞士",
    zhHant: "瑞士"
  },
  {
    en: "Syrian Arab Republic",
    "alpha-3": "SYR",
    zhHans: "叙利亚",
    zhHant: "敘利亞"
  },
  {
    en: "Taiwan",
    "alpha-3": "TWN",
    zhHans: "台湾",
    zhHant: "台灣"
  },
  {
    en: "Tajikistan",
    "alpha-3": "TJK",
    zhHans: "塔吉克斯坦",
    zhHant: "塔吉克"
  },
  {
    en: "Tanzania, United Republic of",
    "alpha-3": "TZA",
    zhHans: "坦桑尼亚",
    zhHant: "坦尚尼亞"
  },
  {
    en: "Thailand",
    "alpha-3": "THA",
    zhHans: "泰国",
    zhHant: "泰國"
  },
  {
    en: "Timor-Leste",
    "alpha-3": "TLS",
    zhHans: "东帝汶",
    zhHant: "東帝汶"
  },
  {
    en: "Togo",
    "alpha-3": "TGO",
    zhHans: "多哥",
    zhHant: "多哥"
  },
  {
    en: "Tokelau",
    "alpha-3": "TKL",
    zhHans: "托克劳",
    zhHant: "托克勞"
  },
  {
    en: "Tonga",
    "alpha-3": "TON",
    zhHans: "汤加",
    zhHant: "東加"
  },
  {
    en: "Trinidad and Tobago",
    "alpha-3": "TTO",
    zhHans: "特立尼达和多巴哥",
    zhHant: "千里達及托巴哥"
  },
  {
    en: "Tunisia",
    "alpha-3": "TUN",
    zhHans: "突尼斯",
    zhHant: "突尼西亞"
  },
  {
    en: "Turkey",
    "alpha-3": "TUR",
    zhHans: "土耳其",
    zhHant: "土耳其"
  },
  {
    en: "Turkmenistan",
    "alpha-3": "TKM",
    zhHans: "土库曼斯坦",
    zhHant: "土庫曼"
  },
  {
    en: "Turks and Caicos Islands",
    "alpha-3": "TCA",
    zhHans: "特克斯和凯科斯群岛",
    zhHant: "特克斯與凱科斯群島"
  },
  {
    en: "Tuvalu",
    "alpha-3": "TUV",
    zhHans: "图瓦卢",
    zhHant: "吐瓦魯"
  },
  {
    en: "Uganda",
    "alpha-3": "UGA",
    zhHans: "乌干达",
    zhHant: "烏干達"
  },
  {
    en: "Ukraine",
    "alpha-3": "UKR",
    zhHans: "乌克兰",
    zhHant: "烏克蘭"
  },
  {
    en: "United Arab Emirates",
    "alpha-3": "ARE",
    zhHans: "阿联酋",
    zhHant: "阿聯"
  },
  {
    en: "United Kingdom of Great Britain and Northern Ireland",
    "alpha-3": "GBR",
    zhHans: "英国",
    zhHant: "英國"
  },
  {
    en: "United States of America",
    "alpha-3": "USA",
    zhHans: "美国",
    zhHant: "美國"
  },
  {
    en: "United States Minor Outlying Islands",
    "alpha-3": "UMI",
    zhHans: "美国本土外小岛屿",
    zhHant: "美國本土外小島嶼"
  },
  {
    en: "Uruguay",
    "alpha-3": "URY",
    zhHans: "乌拉圭",
    zhHant: "烏拉圭"
  },
  {
    en: "Uzbekistan",
    "alpha-3": "UZB",
    zhHans: "乌兹别克斯坦",
    zhHant: "烏茲別克"
  },
  {
    en: "Vanuatu",
    "alpha-3": "VUT",
    zhHans: "瓦努阿图",
    zhHant: "萬那杜"
  },
  {
    en: "Venezuela (Bolivarian Republic of)",
    "alpha-3": "VEN",
    zhHans: "委内瑞拉",
    zhHant: "委內瑞拉"
  },
  {
    en: "Viet Nam",
    "alpha-3": "VNM",
    zhHans: "越南",
    zhHant: "越南"
  },
  {
    en: "Virgin Islands (British)",
    "alpha-3": "VGB",
    zhHans: "英属维尔京群岛",
    zhHant: "英屬維京群島"
  },
  {
    en: "Virgin Islands (U.S.)",
    "alpha-3": "VIR",
    zhHans: "美属维尔京群岛",
    zhHant: "美屬維京群島"
  },
  {
    en: "Wallis and Futuna",
    "alpha-3": "WLF",
    zhHans: "瓦利斯和富图纳",
    zhHant: "瓦利斯和富圖那"
  },
  {
    en: "Western Sahara",
    "alpha-3": "ESH",
    zhHans: "西撒哈拉",
    zhHant: "西撒哈拉"
  },
  {
    en: "Yemen",
    "alpha-3": "YEM",
    zhHans: "也门",
    zhHant: "葉門"
  },
  {
    en: "Zambia",
    "alpha-3": "ZMB",
    zhHans: "赞比亚",
    zhHant: "尚比亞"
  },
  {
    en: "Zimbabwe",
    "alpha-3": "ZWE",
    zhHans: "津巴布韦",
    zhHant: "辛巴威"
  }
];
