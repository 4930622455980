<template>
  <div>
    <v-card class="bazaarCard" v-if="!loading">
      <div
        style="display: flex; align-items: center; flex-direction: column; cursor: pointer"
        @click="$router.push(`/platform/user/${userid}`).catch((e) => e)"
      >
        <avatar-from-user-id :userid="userid" size="100" class="mb-5"></avatar-from-user-id>
        <h1>{{ this.userid }}</h1>
        <h4 style="color: #b0b0b0; text-align: center; padding: 0" v-if="this.profile">
          {{ this.profile.name }}
        </h4>
      </div>
      <div v-if="this.profile" class="mt-5">
        <v-dialog v-model="longText" max-width="600px">
          <v-card max-width="600px">
            <p class="ma-0 pa-8" style="white-space: pre-wrap; line-height: 1.8">{{ this.profile.bio }}</p>
          </v-card>
        </v-dialog>
        <v-row v-if="this.profile.bio">
          <v-col>
            <p style="white-space: pre-wrap; line-height: 1.8">
              {{ this.profile.bio.slice(0, 200) }}
              <a @click="longText = !longText" v-if="this.profile.bio.length > 200">
                {{ $t("profileSocialReadMore") }}...</a
              >
            </p>
          </v-col>
        </v-row>
        <v-row class="py-0">
          <v-col
            cols="12"
            :sm="$screen.width >= 1264 ? '12' : '6'"
            :md="$screen.width >= 1264 ? '12' : '3'"
            style="display: flex; align-items: center"
            class="py-1"
            v-if="this.profile.location"
          >
            <v-icon class="mr-1">mdi-map-marker-outline</v-icon>
            <p class="ma-0 sb nowrap">{{ getCountryName(this.profile.location) }}</p>
          </v-col>

          <v-col
            cols="12"
            :sm="$screen.width >= 1264 ? '12' : '6'"
            :md="$screen.width >= 1264 ? '12' : '3'"
            style="display: flex; align-items: center"
            class="py-1"
            v-if="this.profile.facebook"
          >
            <v-icon class="mr-1">mdi-facebook</v-icon>
            <p class="ma-0 sb nowrap">{{ this.profile.facebook }}</p>
          </v-col>
          <v-col
            cols="12"
            :sm="$screen.width >= 1264 ? '12' : '6'"
            :md="$screen.width >= 1264 ? '12' : '3'"
            style="display: flex; align-items: center"
            class="py-1"
            v-if="this.profile.twitter"
          >
            <v-icon class="mr-1">mdi-twitter</v-icon>
            <p class="ma-0 sb nowrap">{{ this.profile.twitter }}</p>
          </v-col>
          <v-col
            cols="12"
            :sm="$screen.width >= 1264 ? '12' : '6'"
            :md="$screen.width >= 1264 ? '12' : '3'"
            style="display: flex; align-items: center"
            class="py-1"
            v-if="this.profile.instagram"
          >
            <v-icon class="mr-1">mdi-instagram</v-icon>
            <p class="ma-0 sb nowrap">{{ this.profile.instagram }}</p>
          </v-col>
          <v-col
            cols="12"
            :sm="$screen.width >= 1264 ? '12' : '6'"
            :md="$screen.width >= 1264 ? '12' : '3'"
            style="display: flex; align-items: center"
            class="py-1"
            v-if="this.profile.website"
          >
            <v-icon class="mr-1">mdi-earth</v-icon>
            <p class="ma-0 sb">{{ this.profile.website }}</p>
          </v-col>
        </v-row>

        <v-row class="mt-5" v-if="editable"
          ><v-col>
            <v-btn @click="editSocialProfile" dense text color="primary" depressed block>
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card
      class="bazaarCard"
      style="display: flex; justify-content: center; align-items: center"
      min-height="200px"
      v-else
    >
      <v-progress-circular indeterminate color="primary" size="60"></v-progress-circular>
    </v-card>
  </div>
</template>

<script>
import config from "../../config";
import { getPublicRoleInfo } from "../../services/authService";
import AvatarFromUserId from "@/components/Common/AvatarFromUserId.vue";
import countryList from "../../data/countryList";
export default {
  components: {
    AvatarFromUserId
  },
  props: {
    userid: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: false,
    profile: {},
    longText: false
  }),
  async created() {
    this.loading = true;
    if (!this.userid) {
      this.loading = false;
      return;
    }
    const publicRoleInfoResponse = await getPublicRoleInfo(this.$axios, config, this.userid);
    if (publicRoleInfoResponse.status === 200) {
      if (Object.hasOwnProperty.call(publicRoleInfoResponse.data.publicRoleInfo, "profile")) {
        this.profile = publicRoleInfoResponse.data.publicRoleInfo.profile;
      } else {
        this.profile = null;
      }
    }
    this.loading = false;
  },
  methods: {
    getCountryName(alpha) {
      let country = countryList.find((country) => {
        return country["alpha-3"] === alpha;
      });
      return country[this.$i18n.locale];
    },
    editSocialProfile() {
      this.$store.commit("profilePageChange", "social");
      this.$router.push("/platform/profile");
    }
  },
  computed: {
    editable() {
      if (!this.$session.get("token")) {
        return false;
      }
      return this.$store.state.userProfile.userid === this.userid;
    }
  }
};
</script>

<style></style>
