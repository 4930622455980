<template>
  <div class="eventBazaarContainer">
    <div id="displayCard"><EventBazaar :eventName="$route.params.eventName" :stickyPanel="false" /></div>
  </div>
</template>

<script>
import EventBazaar from "@/components/Common/EventBazaar.vue";
import Landing from "../layouts/Landing";
export default {
  components: { EventBazaar },
  created() {
    this.$emit(`update:layout`, Landing);
  }
};
</script>

<style></style>
