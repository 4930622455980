<template>
  <div>
    <div>
      <div class="addArtwork">
        <div class="ma-0 pa-0 socialProfileCard">
          <div :class="filterUser ? 'socialGrid' : ''">
            <div class="eventCard">
              <v-card class="bazaarCard">
                <h1>{{ this.eventName }}</h1>
                <div style="margin-top: 20px">
                  <v-text-field
                    filled
                    rounded
                    single-line
                    hide-details
                    full-width
                    v-model="searchParams['all-search']"
                    data-cy="searchInput"
                    style="border-radius: 30px"
                    clearable
                    @keydown.enter="searchEventWorks"
                    @click:clear="clearSearch"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon color="primary">mdi-magnify</v-icon>
                    </template>
                  </v-text-field>
                </div>
                <div>
                  <h4 v-if="content.title && content.title !== ''">{{ content.title }}</h4>
                  <div
                    v-if="content.body"
                    class="mt-2"
                    :style="longDescription && !expandDescription ? `max-height: 100px; overflow: hidden` : ''"
                    v-html="content.body"
                  ></div>
                  <div class="eventHidden" v-if="longDescription && !expandDescription"></div>
                  <div style="width: 100%; display: flex; justify-content: center; padding: 5px" v-if="longDescription">
                    <v-btn @click="expandDescription = !expandDescription" icon
                      ><v-icon>{{ !expandDescription ? "mdi-chevron-down" : "mdi-chevron-up" }}</v-icon></v-btn
                    >
                  </div>
                </div>
                <div style="display: flex; flex-direction: row; margin-top: 20px" v-if="creatorAddresses.length > 0">
                  <div class="d-flex align-center">
                    <h5 class="ma-0 pa-0">{{ $t("viewCreator") }}</h5>
                  </div>
                  <v-divider vertical class="hidden-sm-and-down mx-6"></v-divider>
                  <div class="hidden-sm-and-up mx-1"></div>

                  <div
                    style="
                      display: flex;
                      align-items: flex-start;
                      flex-direction: column;
                      justify-content: center;
                      flex: 1;
                    "
                  >
                    <v-row class="pa-0 ma-0">
                      <v-chip
                        v-for="user in creatorAddresses"
                        :key="user"
                        @click="filterUser === user ? (filterUser = null) : (filterUser = user)"
                        :color="filterUser === user ? 'primary' : ''"
                        style="cursor: pointer"
                      >
                        <name-tag-from-address
                          :address="user"
                          avatarSize="30"
                          class="py-1"
                          textStyle="font-size: 16px; font-weight: 600; text-transform: none"
                        />
                      </v-chip>
                    </v-row>
                  </div>
                  <div
                    v-if="!!filterUser"
                    style="display: flex; justify-content: center; flex-direction: column"
                    class="hidden-sm-and-down"
                  >
                    <v-btn depressed color="primary" text rounded @click="filterUser = null">{{
                      $t("landingWorkSignup")
                    }}</v-btn>
                  </div>
                </div>
                <div
                  class="hidden-md-and-up"
                  style="display: flex; align-items: center; flex-direction: column; width: 100%; margin-top: 5px"
                  v-if="!!filterUser"
                >
                  <v-btn depressed color="primary" text rounded @click="filterUser = null">{{
                    $t("landingWorkSignup")
                  }}</v-btn>
                </div>
              </v-card>
            </div>

            <div
              class="userInfoPanel"
              :class="stickyPanel ? 'stickyPanel' : null"
              v-if="filterUser"
              :style="{ 'padding-bottom': filterUser ? '' : '0', 'padding-top': filterUser ? '' : '0' }"
            >
              <div id="userIdCard" v-if="filterUserId">
                <user-profile-card :userid="filterUserId" :key="filterUserId" />
              </div>

              <div style="flex: 1"></div>
            </div>
            <div class="gallery">
              <v-card class="bazaarCard">
                <bazaar-gallery
                  :items="filteredItems"
                  :noSmallScaling="!!filterUser"
                  :loading="loading"
                  :hoverElement="true"
                  @goToArtwork="setBackToEventDestination"
                  v-if="!loading"
                />
                <div v-else class="d-flex align-center justify-center" key="skeleton">
                  <v-row>
                    <v-col cols="6" sm="4" md="3" xl="2" v-for="(item, i) in 12" :key="i">
                      <v-skeleton-loader type="card"></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
              <div style="flex: 1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BazaarGallery from "@/components/Common/BazaarGallery.vue";
import NameTagFromAddress from "@/components/Common/NameTagFromAddress.vue";
import UserProfileCard from "@/components/Common/UserProfileCard.vue";
import config from "@/config";
import Landing from "@/layouts/Landing";
import { getUseridFromAddress } from "@/services/authService";
import { displayWorksPage, getAllEvents } from "@/services/workService";
import { getLandingInfo } from "../../services/workService";
export default {
  name: "EventBazaar",
  components: { BazaarGallery, NameTagFromAddress, UserProfileCard },
  props: {
    eventName: {
      type: String
    },
    stickyPanel: {
      type: Boolean,
      default: false
    }
  },
  async created() {
    this.$emit(`update:layout`, Landing);

    this.loading = true;
    this.searchParams["event-name-search"] = this.eventName;
    this.getEventInfo();
    const result = await displayWorksPage(this.$axios, config, this.searchParams);

    if (result.status === 200) {
      this.items = result.data;
      // if (this.items.length === 0) {
      //   this.$router.push("/*").catch((e) => e);
      // }
    } else {
      this.loading = false;
      this.$router.push("/*").catch((e) => e);
    }

    this.loading = false;
    this.$store.commit("setEventName", this.eventName);
  },
  data: () => ({
    items: [],
    loading: false,
    searchParams: {
      "items-per-page": null,
      "page-number": null,
      "work-info-search": null,
      "unmanaged-info-search": null,
      "sorting-by-time": true,
      "sorting-by-like": false,
      "all-search": null
    },
    filterUser: null,
    filterUserId: null,
    event: null,
    expandDescription: false,
    news: null
  }),
  methods: {
    async getEventInfo() {
      this.event = await getAllEvents(this.$axios, config, "", true, true)
        .then((res) => {
          return res.data;
        })
        .then((res) => {
          return res.find((event) => {
            return event.eventName === this.eventName;
          });
        });

      if (this.event && this.event.eventObject.useLandingInfo) {
        const landingInfo = await getLandingInfo(this.$axios, config);

        if (landingInfo.status === 200) {
          this.news = landingInfo.data.find((elem) => {
            return elem.infoid === this.event.eventObject.landingInfoId;
          });
        } else {
          this.success = false;
        }
      }
    },
    async setUserIdAsNewAddress(address) {
      if (!address) {
        this.filterUserId = null;
        return;
      }
      let result = await getUseridFromAddress(this.$axios, config, address)
        .then((res) => {
          if (res.status === 200) {
            return res.data.userid;
          } else {
            return null;
          }
        })
        .catch(() => {
          return null;
        });
      this.filterUserId = result;
    },
    setBackToEventDestination() {
      this.$store.commit("setBackDestination", {
        show: true,
        path: `/platform/bazaar?eventName=${this.eventName}`,
        name: this.eventName,
        override: true
      });
    },
    async goToUserWorks(address) {
      let userid = await getUseridFromAddress(this.$axios, config, address)
        .then((res) => {
          if (res.status === 200) {
            return res.data.userid;
          } else {
            return "";
          }
        })
        .catch(() => {
          return "";
        });
      this.$router.push(`/platform/user/${userid}`);
    },
    async searchEventWorks() {
      this.loading = true;
      const result = await displayWorksPage(this.$axios, config, this.searchParams);

      if (result.status === 200) {
        this.items = result.data;
        // if (this.items.length === 0) {
        //   this.$router.push("/*").catch((e) => e);
        // }
      } else {
        this.loading = false;
        this.$router.push("/*").catch((e) => e);
      }
      this.loading = false;
    },
    clearSearch() {
      this.searchParams["all-search"] = null;
      this.searchEventWorks();
    }
  },
  computed: {
    creatorAddresses() {
      if (this.items === []) {
        return [];
      }
      let creatorAddresses = this.items.map((elem) => {
        return elem.creatorAddress;
      });
      creatorAddresses = creatorAddresses.filter((x, i, a) => a.indexOf(x) === i);
      return creatorAddresses;
    },
    filteredItems() {
      if (!this.filterUser) {
        return this.items;
      } else {
        return this.items.filter((elem) => {
          return elem.creatorAddress === this.filterUser;
        });
      }
    },
    longDescription() {
      if (!this.event) {
        return false;
      }
      if (!this.event.eventObject) {
        return false;
      }
      if (this.event.eventObject.useLandingInfo && this.news) {
        return true;
      }
      if (!this.event.eventObject.description) {
        return false;
      }
      if (this.event.eventObject.description.length < 500) {
        return false;
      }

      return true;
    },
    content() {
      let defaultContent = { title: null, body: null };
      if (this.event) {
        if (this.event.eventObject.useLandingInfo) {
          if (this.news) {
            return {
              title: this.news.landingInfo.title[this.$i18n.locale],
              body: this.$sanitize(this.news.landingInfo.body[this.$i18n.locale])
            };
          } else {
            return defaultContent;
          }
        } else if (this.event.eventObject.title) {
          return { title: this.event.eventObject.title, body: this.$sanitize(this.event.eventObject.description) };
        } else {
          return defaultContent;
        }
      } else {
        return defaultContent;
      }
    }
  },
  watch: {
    filterUser(newValue) {
      this.filterUserId = null;

      this.setUserIdAsNewAddress(newValue);
    }
  }
};
</script>

<style></style>
