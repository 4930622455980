<template>
  <div>
    <div v-if="!loading" key="content">
      <v-row v-if="!loading">
        <v-col
          v-for="(item, i) in items.slice(0, numItems)"
          :key="item.workid.concat(i)"
          class="d-flex child-flex pa-0"
          cols="6"
          :sm="noSmallScaling ? '6' : '4'"
          :md="noSmallScaling ? '4' : '3'"
          :xl="noSmallScaling ? '4' : '2'"
        >
          <v-hover v-slot="{ hover }" style="cursor: pointer">
            <v-card
              :ripple="false"
              class="elevation-0 transition-swing"
              :class="hoverElement ? `pa-${hover ? 2 : 3}` : 'pa-3'"
            >
              <bazaar-carousel-item :item="item" :showTitle="true" :showHashtag="true" />
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <div v-else class="d-flex align-center justify-center" key="skeleton">
      <v-row>
        <v-col cols="6" sm="4" md="3" xl="2" v-for="(item, i) in itemsPerPage" :key="i">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <v-row v-if="(items.length === 0) & !loading" class="pa-3 mt-10 d-flex flex-column align-center">
      <v-icon x-large class="mb-3">mdi-alert-outline</v-icon>
      <h5>{{ $t("bazaarNoResult") }}</h5>
    </v-row>

    <div ref="trigger">
      <v-row
        class="pa-3 mt-10 d-flex flex-column align-center"
        v-if="!loading && numItems < items.length"
        @click="addItems"
        style="cursor: pointer"
      >
        <v-progress-circular indeterminate color="#63a3c3" />
      </v-row>
    </div>
  </div>
</template>

<script>
import BazaarCarouselItem from "../Home/BazaarCarouselItem.vue";

export default {
  components: { BazaarCarouselItem },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    hoverElement: {
      default: true,
      type: Boolean
    },
    redirectPath: {
      default: null,
      type: String
    },
    noSmallScaling: {
      default: false,
      type: Boolean
    },
    infiniteScroll: {
      default: true,
      type: Boolean
    }
  },
  created() {
    this.itemsPerPage = 12;
    if (this.infiniteScroll) {
      this.observer = new IntersectionObserver(this.handleScroll, this.intersectionOptions);
    } else {
      this.numItems = this.items.length;
    }
  },
  mounted() {
    let element = this.$refs.trigger;
    this.observer.observe(element);
  },
  destroyed() {
    if (this.infiniteScroll) {
      this.observer.disconnect();
    }
  },
  data: () => ({
    itemsPerPage: null,
    numItems: 12,
    observer: null,
    intersectionOptions: {
      root: null,
      threshold: 0.5,
      rootMargin: "0px"
    }
  }),
  methods: {
    noTitle(item) {
      if (!item.workInfo.artworkTitle) {
        return true;
      }
      if (!item.workInfo.artworkTitle.replace(/\s/g, "").length) {
        return true;
      }
      return false;
    },
    redirectToArtwork(item) {
      if (this.redirectPath) {
        this.$router.push(this.redirectPath).catch((e) => e);
      } else {
        this.$router.push(`/platform/artwork/${item.workid}`).catch((e) => e);
        this.$emit("goToArtwork");
      }
    },
    cleanKeyPhotoIndex(i) {
      if (!i) {
        return 0;
      }
      if (i < 0) {
        return 0;
      }
      return i;
    },
    handleScroll(entries) {
      const isIntersecting = entries[0]?.isIntersecting ?? false;
      if (isIntersecting) {
        setTimeout(this.addItems, 500);
      }
    },
    addItems() {
      this.numItems = Math.min(this.numItems + 12, Math.max(this.items.length, 12));
    }
  },
  watch: {
    numItems(newVal, oldVal) {
      console.log(oldVal);
      console.log(newVal);
      if (newVal == 0) {
        throw new TypeError("Hi");
      }
    }
  }
};
</script>

<style></style>
