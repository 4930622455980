<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <confirm-dlg ref="confirm" />
  </div>
</template>

<script>
import MessageBox from "../components/Common/MessageBox.vue";
import ConfirmDlg from "../components/Platform/ConfirmDlg.vue";
import config from "../config";
import { checkEvent } from "../services/workService";
export default {
  components: {
    MessageBox,
    ConfirmDlg
  },
  data: () => ({
    messageBox: {
      state: false,
      title: "",
      content: "",
      cssClass: "error",
      destination: ""
    }
  }),
  async created() {
    const eventCode = this.$route.params.eventCode;
    const result = await checkEvent(this.$axios, config, eventCode);
    if (result.status === 200) {
      localStorage.setItem("eventCode", eventCode);
      const messageContent = `Log in and create a new artwork to join the event ${result.data.eventName}!`;
      let proceed = await this.$refs.confirm.open("", messageContent);
      if (proceed) {
        this.$router.push("/platform/new").catch((e) => e);
      } else {
        this.$router.push("/").catch((e) => e);
      }
    } else {
      this.messageBox.content = this.$t(result.data.message);
      this.messageBox.cssClass = "error";
      this.messageBox.state = true;
      this.messageBox.destination = "/";
    }
  }
};
</script>
